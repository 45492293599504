<template>
  <EuiDialog @onClose="$emit('closeDialog')" size="large" :open="true" class="es-docsumentPreviewDialog">
    <template #header>{{ document.content.name }}</template>
    <EuiPDFViewer style="height: calc(100vh - 220px);"
                  :pdf="base64"
                  :currentPage="currentPage"
                  @update:currentPage="updatePage"
                  base64
                  preview
                  pagination
                  :buffer="false"/>
  </EuiDialog>
</template>

<script>
import EuiPDFViewer from '@silae/edoc-ui/src/components/organisms/PDFViewer/PDFViewer';
import { FileUtil } from '../../../utils/file';

export default {
  name: 'DocumentPreviewDialog',
  components: { EuiPDFViewer },
  data() {
    return {
      base64: undefined,
      currentPage: 1,
    };
  },
  props: {
    document: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    updatePage(payload) {
      this.currentPage = payload;
    },
  },
  async created() {
    this.base64 = await FileUtil.getBase64(this.document.content);
  },
};
</script>
